import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { Months } from "../contexts/investment";
import { JSON_API } from "../services/Constants";

import {
  AppstoreOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleFilled,
  CloseOutlined,
  DollarCircleFilled,
  InfoCircleOutlined,
  PlusSquareOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  message,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import { useAbility } from "../contexts/abilityContext";

const { TabPane } = Tabs;

const Investment = () => {
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [Investments, setInvestments] = useState([]);
  const { Option } = Select;

  const [budgets, setBudgets] = useState([]);
  const [reals, setReals] = useState([]);
  const [performances, setPerformances] = useState([]);

  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [Categories, setCategories] = useState([]);
  let { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { ability } = useAbility();
  const canDelete = ability.can("delete", "Investments");
  const canUpdate = ability.can("update", "Investments");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [projectFiles, setProjectFiles] = useState([]);
  const [projectInfo, setProjectInfo] = useState({});
  const [allinv, setallinv] = useState({});

  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageInvestments")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageInvestments", pagination.current);
  };
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const getAllInvestmentsByFilter = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${JSON_API}/Investments/filter/?year=${year}&enterpriseId=${Company.id}`
      );
      setallinv(response);
      if (response.data && Array.isArray(response.data)) {
        setBudgets(response.data);
        setReals(response.data);
        setPerformances(response.data);
      }
    } catch (error) {
      
      console.error(error);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${JSON_API}/InvestmentCategorys`);
      setCategories(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  const fetchProjectFiles = async () => {
    try {
      const response = await axios.get(
        `${JSON_API}/InvestmentProjectFiles?year=${year}&enterpriseId=${Company.id}`
      );
      setProjectFiles(response.data);
    } catch (error) {
      console.error("Error fetching project files:", error);
    }
  };

  const handleCreate = () => {
    if (!year) {
      message.warning(t("Pleaseselectyearbeforeaddinginvestment"));
      return;
    }

    const newInvestment = {
      id: Math.floor(Math.random() * 2 ** 31),
      year: year,
      enterpriseId: Company.id,
      projectNumber: "",
      title: "",
      budgetTotal: 0,
      realTotal: 0,
      performanceTotal: 0,
      budgetConfirmed: false,
      isNew: true,
      investmentCategory: { id: null, label: "" },
      projectFile: allinv.investmentProjectFileId,
      investmentMonthFinanceDetails: Months.map((_, index) => ({
        id: Math.floor(Math.random() * 2 ** 31),
        budget: 0,
        real: 0,
        performance: 0,
        month: index + 1,
      })),
    };

    setBudgets([...budgets, newInvestment]);
    setReals([...reals, newInvestment]);
    setPerformances([...performances, newInvestment]);
  };

  const saveInvestmentToBackend = async (investment) => {
    try {
      const response = await axios.post(
        `${JSON_API}/Investments/create`,
        investment,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      message.success("Investment saved successfully !");
      return response.data;
    } catch (error) {
      message.error(
        `Failed to save the investment : ${error.response.statusText}`
      );
      console.error("Error creating investment:", error);
      throw error;
    }
  };

  const deleteInvestmentFromBackend = async (investmentId) => {
    try {
      const response = await axios.delete(
        `${JSON_API}/Investments/${investmentId}`
      );

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const updateInvestmentToBackend = async (investment) => {
    try {
      const response = await axios.put(
        `${JSON_API}/Investments/update`,
        investment
      );
      return response.data;
    } catch (error) {
      console.error("Error updating investment:", error);
      throw error;
    }
  };

  const isInvestmentExisting = async (investmentId) => {
    try {
      const response = await axios.get(
        `${JSON_API}/Investments/${investmentId}`
      );

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return false;
      }

      console.error("Error checking investment existence:", error);
      throw error;
    }
  };

  const handleSave = () => {
    let allFieldsFilled = true;
    let allSucceeded = true;

    const saveOrUpdateInvestment = async (investment) => {
      const isExistingInvestment = await isInvestmentExisting(investment.id);
      const apiCall = isExistingInvestment
        ? updateInvestmentToBackend(investment)
        : saveInvestmentToBackend(investment);

      return apiCall
        .then((response) => {
          console.log(
            isExistingInvestment ? "Investment updated:" : "Investment saved:",
            response
          );
        })
        .catch((error) => {
          console.error(
            isExistingInvestment
              ? "Error updating investment:"
              : "Error saving investment:",
            error
          );
          allSucceeded = false;
        });
    };

    const investmentsPromises = budgets.map((budget, index) => {
      const real = reals[index];
      const performance = performances[index];
      const investment = createInvestment(budget, real, performance);

      if (investment === null) {
        allFieldsFilled = false;
        return;
      }

      return saveOrUpdateInvestment(investment);
    });

    if (!allFieldsFilled) {
      message.warning(t("Pleasefillallrequiredfields"));
      return;
    }

    Promise.all(investmentsPromises).then(() => {
      if (allSucceeded) {
        message.success(t("Investmentssavedsuccessfully"));
        getAllInvestmentsByFilter();
      } else {
        message.error("Some investments failed to update");
      }
    });
  };

  const createInvestment = (budget, real, performance) => {
    if (!budget || !real || !performance) return null;
    if (
      !budget.projectNumber ||
      !budget.title

    ) {
      return null;
    }

    const investmentDetails = budget.investmentMonthFinanceDetails.map(
      (detail, index) => {
        const budgetValue = detail.budget;
        const realValue = real.investmentMonthFinanceDetails[index].real;
        const performanceValue = budgetValue - realValue;

        return {
          id: detail.id,
          budget: budgetValue,
          real: realValue,
          performance: performanceValue,
          month: detail.month,
        };
      }
    );

    const budgetTotal = investmentDetails.reduce(
      (total, detail) => total + detail.budget,
      0
    );

    const realTotal = investmentDetails.reduce(
      (total, detail) => total + detail.real,
      0
    );

    const performanceTotal = investmentDetails.reduce(
      (total, detail) => total + detail.performance,
      0
    );


    const investmentCategoryId = budget.investmentCategory
      ? budget.investmentCategory.id
      : null;

    // Modification ici pour s'assurer que investmentProjectFileId est correctement défini
    const investmentProjectFileId =
      budget.projectFile?.id || budget.investmentProjectFileId || null;

    const investment = {
      id: budget.id || null,
      year: year,
      enterpriseId: Company.id,
      projectNumber: budget.projectNumber,
      title: budget.title,
      budgetTotal: budgetTotal,
      realTotal: realTotal,
      performanceTotal: performanceTotal,
      budgetConfirmed: budget.budgetConfirmed,
      investmentCategoryId: investmentCategoryId,
      investmentProjectFileId: investmentProjectFileId,
      investmentMonthFinanceDetails: investmentDetails,
    };

    return investment;
  };

  const handleConfirmBudget = (investmentId) => {
    setBudgets((prevBudgets) => {
      return prevBudgets.map((investmentItem) => {
        if (investmentItem.id !== investmentId) {
          return investmentItem;
        }

        const investment = { ...investmentItem };
        investment.budgetConfirmed = !investment.budgetConfirmed;

        if (investment.budgetConfirmed) {
          const matchingRealIndex = reals.findIndex(
            (real) => real.id === investmentId
          );
          if (matchingRealIndex === -1) {
            return investment;
          }

          const matchingReal = { ...reals[matchingRealIndex] };
          investment.investmentMonthFinanceDetails.forEach((detail) => {
            const realDetail = matchingReal.investmentMonthFinanceDetails.find(
              (realDetailItem) => realDetailItem.month === detail.month
            );
            if (realDetail && !realDetail.real) {
              realDetail.real = detail.budget;
            }
          });

          setReals((prevReals) => {
            return prevReals.map((real, index) => {
              if (index === matchingRealIndex) {
                return matchingReal;
              }
              return real;
            });
          });
        }
        return investment;
      });
    });
  };

  const handleDelete = async (investmentId) => {
    if (investmentId) {
      try {
        const isDeleted = await deleteInvestmentFromBackend(investmentId);
        if (isDeleted) {
          message.success("Investment deleted successfully");
          getAllInvestmentsByFilter();
        } else {
          message.error("Failed to Delete the investment");
        }
      } catch (error) {
        message.error(
          `Failed to Delete the investment : ${error.response.statusText}`
        );
      }
    }
  };

  const handleMonthChange = (
    value,
    monthIndex,
    investmentId,
    arrayToUpdate
  ) => {
    const updateInvestments = (prevInvestments) => {
      return prevInvestments.map((investment) => {
        if (investment.id === investmentId) {
          const updatedInvestment = { ...investment };
          const monthDetail =
            updatedInvestment.investmentMonthFinanceDetails.find(
              (detail) => detail.month === monthIndex + 1
            );

          if (monthDetail) {
            if (arrayToUpdate === "budgets") {
              monthDetail.budget = value;
            } else if (arrayToUpdate === "reals") {
              monthDetail.real = value;
            } else {
              return investment;
            }
          }

          updatedInvestment.budgetTotal =
            updatedInvestment.investmentMonthFinanceDetails.reduce(
              (total, detail) => total + detail.budget,
              0
            );
          updatedInvestment.realTotal =
            updatedInvestment.investmentMonthFinanceDetails.reduce(
              (total, detail) => total + detail.real,
              0
            );
          updatedInvestment.performanceTotal =
            updatedInvestment.investmentMonthFinanceDetails.reduce(
              (total, detail) => total + detail.performance,
              0
            );

          return updatedInvestment;
        }
        return investment;
      });
    };

    if (arrayToUpdate === "budgets") {
      setBudgets(updateInvestments);
    } else if (arrayToUpdate === "reals") {
      setReals(updateInvestments);
    }
  };

  const handleProjectNumberChange = (value, id) => {
    const updateInvestment = (investment) =>
      investment.id === id
        ? { ...investment, projectNumber: value }
        : investment;

    setBudgets(budgets.map(updateInvestment));
    setReals(reals.map(updateInvestment));
    setPerformances(performances.map(updateInvestment));
  };

  const handleTitleChange = (value, id) => {
    const updateInvestment = (investment) =>
      investment.id === id ? { ...investment, title: value } : investment;

    setBudgets(budgets.map(updateInvestment));
    setReals(reals.map(updateInvestment));
    setPerformances(performances.map(updateInvestment));
  };

  const handleCategoryChange = (value, id) => {
    const updateInvestment = (investment) => {
      if (investment.id === id) {
        const category = Categories.find((cat) => cat.id === value);
        return { ...investment, investmentCategory: category || {} };
      }
      return investment;
    };

    setBudgets(budgets.map(updateInvestment));
    setReals(reals.map(updateInvestment));
    setPerformances(performances.map(updateInvestment));
  };

  const handleProjectFileChange = async (value, id) => {
    localStorage.setItem(`projectFile_${id}`, value);
    try {
      const response = await axios.get(
        `${JSON_API}/InvestmentProjectFiles/main-info/${value}`
      );
      const projectInfo = response.data;

      const updateProjectFile = (investment) => {
        if (investment.id === id) {
          return {
            ...investment,
            projectFile: {
              id: value,
              projectNumber: projectInfo.projectNumber || "",
              title: projectInfo.projectName || "",
            },
            projectNumber: projectInfo.projectNumber || "",
            title: projectInfo.projectName || "",
            investmentProjectFileId: value, // Mise à jour de investmentProjectFileId
          };
        }
        return investment;
      };

      setBudgets((prevBudgets) => prevBudgets.map(updateProjectFile));
      setReals((prevReals) => prevReals.map(updateProjectFile));
      setPerformances((prevPerformances) =>
        prevPerformances.map(updateProjectFile)
      );
    } catch (error) {
      console.error("Error fetching project info:", error);
      message.error(t("FailedToFetchProjectInfo"));
    }
  };

  const findMatchingRecord = (id, array) => {
    const matchingRecord = array.find((record) => record.id === id);
    return matchingRecord || {};
  };

  const onChangeyear = (date, dateString) => {
    setInvestments();
    setYear(dateString);
  };

  const columnsBudget = [
    {
      title: t("ProjectNumber"),
      dataIndex: "projectNumber",
      key: "projectNumber",
      align: "center",
      width: 200,
      render: (text, record) => {
        const isCreate = record.isNew;
        return (
          <Form.Item
            name="projectNumber"
            style={{ margin: 0, width:200 }}
            rules={[
              { required: true, message: "Please enter a project number!" },
            ]}
          >
            {/* <span>{record.projectNumber}</span> */}
            <span>
              <Input
                defaultValue={text}
                value={record.projectNumber}
                disabled={
                  ability
                    ? isCreate
                      ? !ability.can("create", "Investments")
                      : !ability.can("update", "Investments")
                    : false
                }
                onChange={(e) =>
                  handleProjectNumberChange(e.target.value, record.id)
                }
              />
            </span>
          </Form.Item>
        );
      },
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 200,
      render: (text, record) => {
        const isCreate = record.isNew;

        return (
          <Form.Item
            name="title"
            style={{ margin: 0, width:200 }}
            rules={[{ required: true, message: "Please enter a title!" }]}
          >
            <span>
              <Input
                disabled={
                  ability
                    ? isCreate
                      ? !ability.can("create", "Investments")
                      : !ability.can("update", "Investments")
                    : false
                }
                defaultValue={text}
                value={record.title} // Assurez-vous que le champ 'title' est utilisé ici
                onChange={(e) => handleTitleChange(e.target.value, record.id)}
              />
            </span>
          </Form.Item>
        );
      },
    },

    {
      title: t("ProjectFile"),
      dataIndex: "projectFile",
      key: "projectFile",
      align: "center",
      width: 200,
      render: (text, record) => {
        const isCreate = record.isNew;
        const savedProjectFileId = localStorage.getItem(
          `projectFile_${record.id}`
        );
        return (
          <Form.Item
            name={`projectFile_${record.id}`}
            style={{ margin: 0 }}
            rules={[
              { required: true, message: "Please select a project file!" },
            ]}
          >
            <Select
              disabled={
                ability
                  ? isCreate
                    ? !ability.can("create", "Investments")
                    : !ability.can("update", "Investments")
                  : false
              }
              notFoundContent={t("nodata")}
              defaultValue={record.investmentProjectFileId}
              placeholder="Please Select a Project File"
              style={{ width: "100%" }}
              onChange={(value) => handleProjectFileChange(value, record.id)}
              value={record.investmentProjectFileId} // Utilisation de investmentProjectFileId comme valeur
            >
              {projectFiles.map((file) => (
                <Option key={file.id} value={file.id}>
                  {file.projectName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },

    {
      title: t("Category"),
      dataIndex: "investmentCategory",
      key: "investmentCategory",
      align: "center",
      width: 200,
      render: (text, record) => {
        const category = record.investmentCategory!=null?Categories.find(
          (cat) => cat.id === record.investmentCategory.id
        ):null;
        const isCreate = record.isNew;
        return (
          <Form.Item
            name="investmentCategory"
            style={{ margin: 0 }}
          >
            <Select
              disabled={
                ability
                  ? isCreate
                    ? !ability.can("create", "Investments")
                    : !ability.can("update", "Investments")
                  : false
              }
              notFoundContent={t("nodata")}
              value={undefined}
              defaultValue={category ? category.id : undefined}
              placeholder="Please Select a Category"
              style={{ width: "100%" }}
              onChange={(value) => handleCategoryChange(value, record.id)}
            >
              {Categories.map((category) => (
                <Option key={category.id} value={category.id}>
                  {category.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },

    {
      title: t("priority"),
      dataIndex: "projectOperationalPriority",
      key: "projectOperationalPriority",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (!record.projectOperationalPriority) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.projectOperationalPriority.labelEn
              : record.projectOperationalPriority.labelFr}
          </div>
        );
      },
    },
    {
      title: t("Status"),
      dataIndex: "projectStatus",
      key: "projectStatus",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (!record.projectStatus) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.projectStatus.labelEn
              : record.projectStatus.labelFr}
          </div>
        );
      },
    },

    {
      title: t("Total"),
      dataIndex: "plannedInvestmentTotal",
      key: "plannedInvestmentTotal",
      align: "center",
      width: 200,
      render: (_, record) => {

        if (!record.plannedInvestmentTotal) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {record.plannedInvestmentTotal}
          </div>
        );
      },
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  months.forEach((month, loopIndex) => {
    const monthIndex = (loopIndex + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsBudget.push({
      title: monthName,
      align: "center",
      dataIndex: `month_${monthIndex}`,
      key: `month_${monthIndex}`,
      render: (text, record) => {
        const monthDetail = record.investmentMonthFinanceDetails.find(
          (detail) => detail.month === monthIndex + 1
        );

        const isCreate = record.isNew;

        const budgetValue =
          monthDetail && monthDetail.budget ? monthDetail.budget : 0;
        const budgetConfirmedd = budgets.find(
          (budget) => budget.id === record.id
        )?.budgetConfirmed;
        return (
          <Form.Item
            name={`month_${monthIndex}`}
            style={{ textAlign: "right", margin: 0 }}
          >
            <InputNumber
              style={{ textAlign: "right" }}
              defaultValue={budgetValue.toFixed(0)}
              disabled={
                budgetConfirmedd ||
                (ability
                  ? isCreate
                    ? !ability.can("create", "Investments")
                    : !ability.can("update", "Investments")
                  : false)
              }
              onChange={(value) =>
                handleMonthChange(value, monthIndex, record.id, "budgets")
              }
            />
          </Form.Item>
        );
      },
    });
  });

  columnsBudget.push(
    {
      title: t("BudgetTotal"),
      dataIndex: "budgetTotal",
      align: "center",
      key: "budgetTotal",
      render: (text, record) => {
        const total = record.investmentMonthFinanceDetails.reduce(
          (sum, detail) => sum + detail.budget,
          0
        );
        return <span>{total.toFixed(0)}</span>;
      },
    },
    {
      title: t("BudgetConfirmed"),
      dataIndex: "budgetConfirmed",
      align: "center",
      key: "budgetConfirmed",
      with: 150,
      render: (text, record) => {
        const isCreate = record.isNew;
        return text ? (
          <div className="subsections-container">
            <Tag color="green" icon={<CheckCircleOutlined />}>
              {t("Confirmed")}
            </Tag>
          </div>
        ) : isCreate ? (
          <Button
            size="medium"
            type="primary"
            disabled={ability && !ability.can("create", "Investments")}
            style={{ borderRadius: "20px", textTransform: "uppercase" }}
            onClick={() => handleConfirmBudget(record.id)}
          >
            {record.budgetConfirmed ? t("Unconfirm") : t("Confirm")}
          </Button>
        ) : (
          <Button
            size="medium"
            type="primary"
            primary
            disabled={ability && !ability.can("update", "Investments")}
            style={{ borderRadius: "20px", textTransform: "uppercase" }}
            onClick={() => handleConfirmBudget(record.id)}
          >
            {record.budgetConfirmed ? t("Unconfirm") : t("Confirm")}
          </Button>
        );
      },
    }
  );

  if (canDelete) {
    columnsBudget.push({
      title: t("Actions"),
      key: "actions",
      align: "center",
      render: (text, record) => {
        return (
          <>
            {ability && ability.can("delete", "Investments") && (
              <Popconfirm
                title="Are you sure to delete this investment ?"
                onConfirm={() => handleDelete(record.id)}
                okButtonProps={{
                  type: "primary",
                  danger: true,
                  icon: <CheckOutlined />,
                  style: { backgroundColor: "#f5222d", borderColor: "#f5222d" },
                }}
                cancelButtonProps={{
                  icon: <CloseOutlined />,
                }}
              >
                <Button type="link" danger icon={<CloseCircleFilled />}>
                  {t("Delete")}
                </Button>
              </Popconfirm>
            )}
          </>
        );
      },
    });
  }

  const columnsReal = [
    {
      title: t("ProjectNumber"),
      dataIndex: "projectNumber",
      key: "projectNumber",
      align: "center",
      width: 150,
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 150,
    },
    {
      title: t("ProjectFile"),
      dataIndex: "projectFile",
      key: "projectFile",
      align: "center",
      width: 200,
      render: (text, record) => {
        const isCreate = record.isNew;

        return (
          <Form.Item
            name={`projectFile_${record.id}`}
            style={{ margin: 0 }}
            rules={[
              { required: true, message: "Please select a project file!" },
            ]}
          >
            <Select
              // disabled={
              //   ability
              //     ? isCreate
              //       ? !ability.can("create", "Investments")
              //       : !ability.can("update", "Investments")
              //     : false
              // }
              disabled={true}
              notFoundContent={t("nodata")}
              defaultValue={record.investmentProjectFileId}
              placeholder="Please Select a Project File"
              style={{ width: "100%" }}
              onChange={(value) => handleProjectFileChange(value, record.id)}
              value={record.investmentProjectFileId} // Utilisation de investmentProjectFileId comme valeur
            >
              {projectFiles.map((file) => (
                <Option key={file.id} value={file.id}>
                  {file.projectName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },

    {
      title: t("Category"),
      dataIndex: "investmentCategory",
      key: "investmentCategory",
      align: "center",
      width: 150,
      render: (text, record) => {
        const category = record.investmentCategory!=null?Categories.find(
          (cat) => cat.id === record.investmentCategory.id
        ):null;
        return (
          <span
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            onClick={() =>
              category ? showNameModal(category.label) : showNameModal("")
            }
          >
            {category ? category.label : ""}
          </span>
        );
      },
    },

    {
      title: t("priority"),
      dataIndex: "projectOperationalPriority",
      key: "projectOperationalPriority",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (!record.projectOperationalPriority) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.projectOperationalPriority.labelEn
              : record.projectOperationalPriority.labelFr}
          </div>
        );
      },
    },
    {
      title: t("Status"),
      dataIndex: "projectStatus",
      key: "projectStatus",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (!record.projectStatus) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.projectStatus.labelEn
              : record.projectStatus.labelFr}
          </div>
        );
      },
    },

    {
      title: t("Total"),
      dataIndex: "plannedInvestmentTotal",
      key: "plannedInvestmentTotal",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (!record.plannedInvestmentTotal) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {record.plannedInvestmentTotal}
          </div>
        );
      },
    },
  ];

  months.forEach((month, loopIndex) => {
    const monthIndex = (loopIndex + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];
    columnsReal.push({
      title: monthName,
      align: "center",
      dataIndex: `month_${monthIndex}`,
      key: `month_${monthIndex}`,
      render: (text, record) => {
        const monthDetail = record.investmentMonthFinanceDetails.find(
          (detail) => detail.month === monthIndex + 1
        );
        const budgetConfirmed = budgets.find(
          (budget) => budget.id === record.id
        )?.budgetConfirmed;
        return (
          <Form.Item name={`month_${monthIndex}`} style={{ margin: 0 }}>
            <InputNumber
              style={{ textAlign: "right" }}
              defaultValue={monthDetail ? monthDetail.real : 0}
              disabled={
                !budgetConfirmed ||
                (ability ? !ability.can("update", "Investments") : false)
              }
              onChange={(value) =>
                handleMonthChange(value, monthIndex, record.id, "reals")
              }
            />
          </Form.Item>
        );
      },
    });
  });

  columnsReal.push({
    title: t("RealTotal"),
    dataIndex: "realTotal",
    align: "center",
    key: "realTotal",
    render: (text, record) => {
      const total = record.investmentMonthFinanceDetails.reduce(
        (sum, detail) => sum + detail.real,
        0
      );
      return <div style={{ textAlign: "right" }}>{total.toFixed(0)}</div>;
    },
  });

  const columnsPerformance = [
    {
      title: t("ProjectNumber"),
      dataIndex: "projectNumber",
      key: "projectNumber",
      align: "center",
      width: 150,
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 150,
    },

    {
      title: t("ProjectFile"),
      dataIndex: "projectFile",
      key: "projectFile",
      align: "center",
      width: 200,
      render: (text, record) => {
        const isCreate = record.isNew;

        return (
          <Form.Item
            name={`projectFile_${record.id}`}
            style={{ margin: 0 }}
            rules={[
              { required: true, message: "Please select a project file!" },
            ]}
          >
            <Select
              disabled={true}
              notFoundContent={t("nodata")}
              defaultValue={record.investmentProjectFileId}
              placeholder="Please Select a Project File"
              style={{ width: "100%" }}
              onChange={(value) => handleProjectFileChange(value, record.id)}
              value={record.investmentProjectFileId} // Utilisation de investmentProjectFileId comme valeur
            >
              {projectFiles.map((file) => (
                <Option key={file.id} value={file.id}>
                  {file.projectName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },

    {
      title: t("Category"),
      dataIndex: "investmentCategory",
      key: "investmentCategory",
      align: "center",
      width: 150,
      render: (text, record) => {
        const category = record.investmentCategory!=null?Categories.find(
          (cat) => cat.id === record.investmentCategory.id
        ):null;
        return (
          <span
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            onClick={() =>
              category ? showNameModal(category.label) : showNameModal("")
            }
          >
            {category ? category.label : ""}
          </span>
        );
      },
    },
    {
      title: t("priority"),
      dataIndex: "projectOperationalPriority",
      key: "projectOperationalPriority",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (!record.projectOperationalPriority) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.projectOperationalPriority.labelEn
              : record.projectOperationalPriority.labelFr}
          </div>
        );
      },
    },
    {
      title: t("Status"),
      dataIndex: "projectStatus",
      key: "projectStatus",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (!record.projectStatus) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.projectStatus.labelEn
              : record.projectStatus.labelFr}
          </div>
        );
      },
    },

    {
      title: t("Total"),
      dataIndex: "plannedInvestmentTotal",
      key: "plannedInvestmentTotal",
      align: "center",
      width: 200,
      render: (_, record) => {
        if (!record.plannedInvestmentTotal) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }

        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {record.plannedInvestmentTotal}
          </div>
        );
      },
    },
  ];

  for (let i = 0; i < months.length; i++) {
    const monthIndex = (i + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    columnsPerformance.push({
      title: monthName,
      align: "center",
      dataIndex: `month_${monthIndex}`,
      key: `month_${monthIndex}`,
      children: [
        {
          title: <span className="columnHeader"> {t("Budget")}</span>,
          dataIndex: `budget_month_${monthIndex}`,
          key: `budget_month_${monthIndex}`,
          align: "center",
          width: 100,
          render: (text, record) => {
            const monthDetail = record.investmentMonthFinanceDetails.find(
              (detail) => detail.month === monthIndex + 1
            );
            const budgetValue = monthDetail
              ? parseFloat(monthDetail.budget)
              : 0;

            return (
              <div style={{ textAlign: "right" }}>{budgetValue.toFixed(0)}</div>
            );
          },
        },
        {
          title: <span className="columnBlue"> {t("Real")}</span>,
          dataIndex: `real_month_${monthIndex}`,
          key: `real_month_${monthIndex}`,
          align: "center",
          width: 100,
          render: (text, record) => {
            const monthDetail = record.investmentMonthFinanceDetails.find(
              (detail) => detail.month === monthIndex + 1
            );
            const budgetValue = monthDetail ? parseFloat(monthDetail.real) : 0;

            return (
              <div style={{ textAlign: "right" }}>{budgetValue.toFixed(0)}</div>
            );
          },
        },
        {
          title: <span> {t("Difference")}</span>,
          dataIndex: `diff_month_${monthIndex}`,
          key: `diff_month_${monthIndex}`,
          align: "center",
          width: 100,
          render: (text, record) => {
            const monthDetail = record.investmentMonthFinanceDetails.find(
              (detail) => detail.month === monthIndex + 1
            );

            const budgetValue = monthDetail
              ? parseFloat(monthDetail.budget)
              : 0;
            const realValue = monthDetail ? parseFloat(monthDetail.real) : 0;
            const difference = realValue - budgetValue;

            const style = {
              textAlign: "center",
              fontWeight: "bold",
              color: difference < 0 ? "red" : "blue",
            };

            return <div style={style}>{difference.toFixed(0)}</div>;
          },
        },
      ],
    });
  }

  columnsPerformance.push({
    title: t("PerformanceTotal"),
    align: "center",
    children: [
      {
        title: <span className="columnHeader"> {t("Budgets")}</span>,
        dataIndex: "budgetTotal",
        key: "budgetTotal",
        align: "center",
        render: (_, record) => {
          const budgetRecord = findMatchingRecord(record.id, budgets);
          return (
            <div style={{ textAlign: "right" }}>
              {parseFloat(budgetRecord.budgetTotal).toFixed(0)}
            </div>
          );
        },
      },
      {
        title: <span className="columnBlue"> {t("Reals")}</span>,
        dataIndex: "realTotal",
        key: "realTotal",
        align: "center",
        render: (_, record) => {
          const realRecord = findMatchingRecord(record.id, reals);
          return (
            <div style={{ textAlign: "right" }}>
              {parseFloat(realRecord.realTotal).toFixed(0)}
            </div>
          );
        },
      },
      {
        title: <span> {t("Difference")}</span>,
        dataIndex: "performanceTotal",
        key: "performanceTotal",
        align: "center",
        render: (_, record) => {
          const totalDifference = Months.map((_, index) => {
            const budgetRecord = findMatchingRecord(record.id, budgets);
            const realRecord = findMatchingRecord(record.id, reals);

            const budgetDetail =
              budgetRecord?.investmentMonthFinanceDetails?.find(
                (detail) => detail.month === index + 1
              );
            const realDetail = realRecord?.investmentMonthFinanceDetails?.find(
              (detail) => detail.month === index + 1
            );

            const budgetValue = budgetDetail
              ? parseFloat(budgetDetail.budget)
              : 0;
            const realValue = realDetail ? parseFloat(realDetail.real) : 0;

            return realValue - budgetValue;
          }).reduce((acc, cur) => acc + cur, 0);

          const style = {
            textAlign: "center",
            fontWeight: "bold",
            color: totalDifference < 0 ? "red" : "blue",
          };

          return <div style={style}>{totalDifference.toFixed(0)}</div>;
        },
      },
    ],
  });

  useEffect(() => {
    fetchCategories();
    fetchCategories();
    fetchProjectFiles();
    if (year) {
      getAllInvestmentsByFilter();
    }
  }, [Company.id, year]);

  return (
    <>
      <Card
        title={
          <span style={{ fontSize: "16px" }}>
            <DollarCircleFilled /> {t("Investments")}
          </span>
        }
      >
        <div style={{ textAlign: "center", marginBottom: 15 }}>
          <h1>
            {t("Investments")} ({year ? year : t("SelectedYear")})
          </h1>
        </div>

        <Space style={{ marginBottom: 20 }}>
          {ability &&
            (ability.can("create", "Investments") ||
              ability.can("update", "Investments")) && (
              <Button type="primary" size="medium" onClick={handleSave}>
                <SaveOutlined /> {t("SaveChanges")}
              </Button>
            )}
          <DatePicker
            name="year"
            picker="year"
            placeholder={t("SelectedYear")}
            style={{ width: 200, height: 35, marginLeft: 20 }}
            onChange={onChangeyear}
          />
        </Space>
        <Space style={{ marginBottom: 20, float: "right" }}>
          <Link
            to={{
              pathname: `/summaryInvestments`,
            }}
          >
            <Button size="medium" type="primary">
              <InfoCircleOutlined /> {t("Summaries")}
            </Button>
          </Link>
          <Link
            to={{
              pathname: `/categoryInvestments`,
            }}
          >
            <Button size="medium" type="primary">
              <AppstoreOutlined /> {t("Categories")}
            </Button>
          </Link>
          {ability && ability.can("create", "Investments") && (
            <Button size="medium" type="primary" onClick={handleCreate}>
              <PlusSquareOutlined /> {t("CreateInvestment")}
            </Button>
          )}
        </Space>

        <Tabs
          defaultActiveKey="Budget"
          style={{
            marginBottom: 35,
            width: "100%",
          }}
          type="card"
          centered
        >
          <TabPane tab={t("Budgets")} key="Budget">
            <Table
              locale={{
                emptyText: <Empty description={t("nodata")} />,
              }}
              style={{ marginBottom: 20 }}
              columns={columnsBudget}
              dataSource={budgets}
              rowKey="id"
              scroll={{
                x: 3000,
              }}
              bordered
              pagination={{ current: currentPage }}
              onChange={handleTableChange}
            />
          </TabPane>
          <TabPane tab={t("Reals")} key="Real">
            <Table
              locale={{
                emptyText: <Empty description={t("nodata")} />,
              }}
              columns={columnsReal}
              dataSource={reals}
              rowKey="id"
              scroll={{
                x: 3000,
              }}
              bordered
            />
          </TabPane>
          <TabPane tab={t("Performances")} key="Performance">
            <div style={{ overflowX: "auto", backgroundColor: "white" }}>
              <Table
                locale={{
                  emptyText: <Empty description={t("nodata")} />,
                }}
                columns={columnsPerformance}
                dataSource={performances}
                rowKey="id"
                scroll={{
                  x: 3000,
                }}
                bordered
              />
            </div>
            <Modal
              title={t("detail")}
              visible={isModalVisible}
              onOk={closeModal}
              onCancel={closeModal}
            >
              <p>{selectedName}</p>
            </Modal>
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};
Investment.acl = {
  subject: "Investments",
  action: "read",
};

export default Investment;
