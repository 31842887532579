// PredictionButtons.js
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

// ["profile", "history", "system", "unit_sold"]
const predictionTypes = (t) => [
  {
    type: "profile",
    label: t("ProfileBased"),
  },
  {
    type: "history",
    label: t("HistoryBased"),
  },
  {
    type: "system",
    label: t("SystemBased"),
  },
  {
    type: "unit_sold",
    label: t("UnitSoldBased"),
  },
];

const PredictionButtons = ({
  selectedPrediction,
  handlePredictionChange,
  canDisplayResults,
  buttonClass,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap justify-center mb-4">
      {predictionTypes(t).map((predictionType) => (
        <Button
          key={predictionType.type}
          className={
            selectedPrediction === predictionType.type
              ? "bg-blue-700"
              : "bg-blue-500" + " mx-1"
          }
          type="primary"
          onClick={() => handlePredictionChange(predictionType.type)}
          disabled={!canDisplayResults}
        >
          {predictionType.label}
        </Button>
      ))}
    </div>
  );
};

export default PredictionButtons;
