import { SyncOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { t } from "i18next";

import { useEffect, useState } from "react";
import Popup from "./Popup";
import FinancialTableSimulation from "./FinancialTableSimulation";
import { useTranslation } from "react-i18next";

export default function SimulationModal({
  open,
  handleClose,
  enterprise,
  revenue,
}) {
  const { t } = useTranslation();

  const [simulationVisibility, setSimulationVisibility] = useState({
    revenueSimulationVisible: true,
    financialTableVisible: false,
  });
  const handleShowRevenueSimulation = () => {
    setSimulationVisibility({
      revenueSimulationVisible: true,
      financialTableVisible: false,
    });
  };
  const handleShowFinancialTable = () => {
    setSimulationVisibility({
      revenueSimulationVisible: false,
      financialTableVisible: true,
    });
  };

  return (
    <Modal
      title={
        <span>
          <SyncOutlined />{" "}
          {simulationVisibility.revenueSimulationVisible
            ? t("RevenuesBudgetsSimulation")
            : t("FinancialTableSimulation")}
        </span>
      }
      open={open}
      onCancel={handleClose}
      width={1000}
      cancelText={t("cancel")}
      className="RevenueSimulationModal"
      footer={null}
    >
      {simulationVisibility.revenueSimulationVisible && (
        <Popup
          showFinancialTable={handleShowFinancialTable}
          enterpriseId={enterprise.id}
          revenue={revenue}
        />
      )}
      {simulationVisibility.financialTableVisible && (
        <>
          <FinancialTableSimulation
            enterpriseId={enterprise.id}
            showRevenueSimulation={handleShowRevenueSimulation}
          />
        </>
      )}
    </Modal>
  );
}
