import React from "react";
import { Card, Table } from "antd";
import { useTranslation } from "react-i18next";

const AdditionalInfoCard = ({ data }) => {
  const { t } = useTranslation();
  const carnetOpportunite = data.carnetOppTotal * data.pourcentageClosing;
  const total =
    parseFloat(data.carnetDeCommande) + parseFloat(carnetOpportunite);

  const dataSource = [
    {
      key: "carnetCommande",
      label: t("OrderBook"),
      value: data.carnetDeCommande.toLocaleString(),
    },
    {
      key: "pourcentage",
      label: t("OpportunityConversionRate"),
      value: `${(data.pourcentageClosing * 100).toFixed(1)}%`,
    },
    {
      key: "carnetOppTotal",
      label: t("TotalOpportunityBook"),
      value: data.carnetOppTotal.toLocaleString(),
    },
    {
      key: "carnetOpportunite",
      label: t("OpportunityBook"),
      value: carnetOpportunite.toLocaleString(),
    },
    { key: "total", label: "", value: total.toLocaleString(), isTotal: true },
  ];

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      render: (text, record) => (record.isTotal ? "" : text),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "right",
      render: (text, record) => (
        <span
          style={
            record.isTotal
              ? {
                  fontWeight: "bold",
                  backgroundColor: "#4CAF50",
                  color: "white",
                  padding: "2px 5px",
                }
              : {}
          }
        >
          {text}
        </span>
      ),
    },
  ];

  return (
    <Card
      title={t("AdditionalInformation")}
      style={{ marginTop: "20px", width: "300px", float: "right" }}
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </Card>
  );
};

export default AdditionalInfoCard;
