import {
  Button,
  DatePicker,
  Descriptions,
  Tag,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useAbility } from "../contexts/abilityContext";
import {
  EditFilled,
  PlusCircleFilled,
  CloseCircleFilled,
  SaveFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import { useTranslation } from "react-i18next";
dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text, Link, Title } = Typography;
const { TextArea } = Input;

const OpportunityDetails = () => {
  const { ability } = useAbility();
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [OrderDetails, setOrderDetails] = useState();
  const [Customer, setCustomer] = useState();
  const [Product, setProduct] = useState();
  const [MarketingType, setMarketingType] = useState();
  const [SummaryDetails, setSummaryDetails] = useState(null);

  const [editingRow, setEditingRow] = useState(null);
  const [editingRowbook, setEditingRowbook] = useState(null);
  const [datebegin, setDatebegin] = useState(null);

  const [costDataSource, setRevenueDataSource] = useState();
  const [RowRevenueData, setRowRevenueData] = useState();

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageOpportunityDetails")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageOpportunityDetails", pagination.current);
  };
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const { id } = useParams();
  const stateParamVal = useLocation().state.stateParam;

  console.log("props parametre value", id);
  console.log("props state value", stateParamVal);

  const disabledDate = (current) => {
    return current && current < datebegin;
  };

  const StatefulModalContent = (props) => {
    return (
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="customer"
              label={t("Customer")}
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseselectacustomer")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("selectcustomer")}
              >
                {Customer?.map(
                  (e) => e && <Option value={e.id}>{e.name}</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="product"
              label={t("Product")}
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseselectaproduct")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("selectproduct")}
              >
                {Product?.map(
                  (e) => e && <Option value={e.id}>{e.label}</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="marketingType"
              label={t("marketingType")}
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseselectamarketingtype")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("selectmarketingtype")}
              >
                {MarketingType?.map(
                  (e) => e && <Option value={e.id}>{e.label}</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="startDate"
              label={t("Startdate")}
              rules={[
                {
                  required: true,
                  message: `${t("Selectdate")}`,
                },
              ]}
            >
              <DatePicker
                style={{ width: 250 }}
                placeholder={t("selectdatedebut")}
                onChange={(date) => setDatebegin(date)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="endDate"
              label={t("Enddate")}
              rules={[
                {
                  required: true,
                  message: `${t("Selectdate")}`,
                },
              ]}
            >
              <DatePicker
                disabled={datebegin == null ? true : false}
                style={{ width: 250 }}
                placeholder={t("selectdatefin")}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="status"
              label={t("statut")}
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseselectastatus")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("Selectopportunitystatus")}
              >
                <Option value={0}>{t("Open")}</Option>
                <Option value={1}>{t("Waiting")}</Option>
                <Option value={2}>{t("Suspended")}</Option>
                <Option value={4}>{t("Lost")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="pricePerDay" label={t("Priceperday")}>
              <InputNumber
                min={0}
                size={"large"}
                formatter={(value) => `$${value}`}
                parser={(value) => value.replace("$", "")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          rules={[
            {
              required: true,
              message: `${t("Descriptionplaceholder")}`,
            },
          ]}
          name="description"
          label={t("Description")}
        >
          <Input type="textarea" />
        </Form.Item>
      </Form>
    );
  };

  const CollectionCreateForm = ({ open, onCreate, onCancel, data }) => {
    {
      return data.data === "OrderDetail" ? (
        <Modal
          width={900}
          open={open}
          cancelButtonProps={{
            danger: true,
            type: "primary",
          }}
          title={t("Createanewopportunity")}
          okText={
            <>
              <PlusCircleFilled /> {t("Create")}
            </>
          }
          cancelText={
            <>
              <CloseCircleFilled /> {t("Cancel")}
            </>
          }
          onCancel={onCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate({ values: values, url: data.url, data: data.data });
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <StatefulModalContent />
        </Modal>
      ) : data.data === "Customer" ? (
        <Modal
          open={open}
          title={t("CreateanewCustomer")}
          cancelButtonProps={{
            danger: true,
            type: "primary",
          }}
          okText={
            <>
              <PlusCircleFilled /> {t("Create")}
            </>
          }
          cancelText={
            <>
              <CloseCircleFilled /> {t("Cancel")}
            </>
          }
          onCancel={onCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate({ values: values, url: data.url, data: data.data });
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form form={form} layout="vertical" name="form_in_modal">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("writeacostumer")}`,
                },
              ]}
            >
              <Input placeholder={t("writeacostumerplaceholder")} />
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        <Modal
          open={open}
          title={t("CreateanewProduct")}
          cancelButtonProps={{
            danger: true,
            type: "primary",
          }}
          okText={
            <>
              <PlusCircleFilled /> {t("Create")}
            </>
          }
          cancelText={
            <>
              <CloseCircleFilled /> {t("Cancel")}
            </>
          }
          onCancel={onCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate({ values: values, url: data.url, data: data.data });
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form form={form} layout="vertical" name="form_in_modal">
            <Form.Item
              name="label"
              rules={[
                {
                  required: true,
                  message: `${t("writeaproduct")}`,
                },
              ]}
            >
              <Input placeholder={t("writeaproductplaceholder")} />
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  };

  useEffect(() => getData(), []);

  const handleErrors = (error) => {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);
  };

  const getData = async () => {
    try {
      const customerResponse = await axios.get(
        `${JSON_API}/Customers/enterprise/${Company.id}`
      );
      setCustomer(customerResponse.data);

      const marketingTypeResponse = await axios.get(
        `${JSON_API}/Marketing/MarketingType`
      );

      setMarketingType(marketingTypeResponse.data);

      const productResponse = await axios.get(
        `${JSON_API}/Products/enterprise/${Company.id}`
      );
      setProduct(productResponse.data);

      const opportunityResponse = await axios.get(
        `${JSON_API}/Opportunities/opportunityBook/${stateParamVal}`
      );

      console.log("opportunityResponse values are :", opportunityResponse.data);

      const newState = opportunityResponse.data.map((obj) => ({
        ...obj,
        key: obj.id,
      }));

      console.log("newState values are :", newState);

      setOrderDetails(newState);

      const summaryResponse = await axios.get(
        `${JSON_API}/OpportunityBooks/opportunityBookMonthlySummary/${stateParamVal}`
      );
      setSummaryDetails(summaryResponse.data);
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleshareholderDelete = async (id) => {
    await axios
      .delete(`${JSON_API}/Opportunities/${id}`)
      .then(() => {
        console.log("opportunity " + id + " deleted successfully");
        getData();
        message.success(`${t("Opportunitydeletedsuccessfully")}`);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const monthsRevenue = [
    "januaryRevenue",
    "februaryRevenue",
    "marchRevenue",
    "aprilRevenue",
    "mayRevenue",
    "juneRevenue",
    "julyRevenue",
    "augustRevenue",
    "septemberRevenue",
    "octoberRevenue",
    "novemberRevenue",
    "decemberRevenue",
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  const costdetailsColumns = [];
  for (let i = 0; i < months.length; i++) {
    const monthIndex = (i + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    costdetailsColumns.push({
      title: monthName,
      align: "center",
      dataIndex: monthsRevenue[monthIndex],
      width: 120,

      render: (text, record) => {
        if (editingRow === record.key) {
          return (
            <Form.Item name={monthsRevenue[monthIndex]}>
              <InputNumber />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{text}</div>;
        }
      },
    });
  }

  costdetailsColumns.push(
    {
      title: <h3 style={{ textAlign: "center" }}>Total $</h3>,
      dataIndex: "total",
      key: "total",
      fixed: "right",
      width: 130,
      render: (_, record) => {
        return <div style={{ textAlign: "right" }}>{record.total}</div>;
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>Actions</h3>,
      dataIndex: "action",
      fixed: "right",
      width: 180,
      render: (_, record) =>
        costDataSource.length >= 1 ? (
          <Space size="middle">
            {editingRow === record.key ? (
              <>
                <Button
                  icon={<CloseCircleFilled />}
                  type="link"
                  danger
                  onClick={() => setEditingRow(null)}
                >
                  {t("Cancel")}
                </Button>
                <Button type="link" icon={<SaveFilled />} htmlType="submit">
                  {t("save")}
                </Button>
              </>
            ) : (
              <>
                {ability.can("update", "Opportunity Book") && (
                  <Popconfirm
                    type="link"
                    onClick={() => {
                      setEditingRow(record.key);
                      form1.setFieldsValue({
                        costPerDay: record.costPerDay,
                        januaryRevenue: record.januaryRevenue,
                        februaryRevenue: record.februaryRevenue,
                        marchRevenue: record.marchRevenue,
                        aprilRevenue: record.aprilRevenue,
                        mayRevenue: record.mayRevenue,
                        juneRevenue: record.juneRevenue,
                        julyRevenue: record.julyRevenue,
                        augustRevenue: record.augustRevenue,
                        septemberRevenue: record.septemberRevenue,
                        octoberRevenue: record.octoberRevenue,
                        novemberRevenue: record.novemberRevenue,
                        decemberRevenue: record.decemberRevenue,
                      });
                    }}
                  >
                    <Button type="link" icon={<EditFilled />}>
                      {t("edit")}
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
          </Space>
        ) : null,
    }
  );

  const columns = [
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Customer")}</h3>,
      dataIndex: "name",
      align: "center",

      render: (text, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseentercustomername")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("Pleaseselectacustomer")}
                style={{
                  width: 120,
                }}
              >
                {Customer?.map(
                  (e) => e && <Option value={e.id}>{e.name}</Option>
                )}
              </Select>
            </Form.Item>
          );
        } else {
          return <>{record.customer === null ? "__" : record.customer.name}</>;
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Product")}</h3>,
      dataIndex: "productId",
      align: "center",

      render: (_, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              name="product"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseenterproductlabel")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("Pleaseselectaproduct")}
                style={{
                  width: 200,
                }}
              >
                {Product?.map(
                  (e) => e && <Option value={e.id}>{e.label}</Option>
                )}
              </Select>
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() =>
                record.product === null
                  ? showNameModal("__")
                  : showNameModal(record.product.label)
              }
            >
              {record.product === null ? "__" : record.product.label}
            </div>
          );
        }
      },
    },

    {
      title: <h3 style={{ textAlign: "center" }}>{t("marketingType")}</h3>,
      dataIndex: "marketingTypeId",
      align: "center",

      render: (_, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              name="marketingType"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseselectamarketingtype")}`,
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("Pleaseselectamarketingtype")}
                style={{
                  width: 200,
                }}
              >
                {MarketingType?.map(
                  (e) => e && <Option value={e.id}>{e.label}</Option>
                )}
              </Select>
            </Form.Item>
          );
        } else {
          return (
            <>
              {record.marketingType === null
                ? "__"
                : record.marketingType.label}
            </>
          );
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Startdate")}</h3>,
      dataIndex: "startDate",
      align: "center",

      render: (_, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              name="startDate"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseenteradate")}`,
                },
              ]}
            >
              <DatePicker
                placeholder={t("selectdate")}
                onChange={(date) => setDatebegin(date)}
              />
            </Form.Item>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              {dayjs(record.startDate).format("YYYY/MM/DD")}
            </div>
          );
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Enddate")}</h3>,
      dataIndex: "endDate",
      align: "center",
      render: (_, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              name="endDate"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseenteradate")}`,
                },
              ]}
            >
              <DatePicker
                placeholder={t("selectdate")}
                disabledDate={disabledDate}
              />
            </Form.Item>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              {dayjs(record.endDate).format("YYYY/MM/DD")}
            </div>
          );
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("statut")}</h3>,
      dataIndex: "status",
      align: "center",
      render: (_, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: `${t("Selectopportunitystatus")}`,
                },
              ]}
              name="status"
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("Selectopportunitystatus")}
              >
                <Option value={0}>{t("Open")}</Option>
                <Option value={1}>{t("Waiting")}</Option>
                <Option value={2}>{t("Suspended")}</Option>
                <Option value={3}>{t("Gained")}</Option>
                <Option value={4}>{t("Lost")}</Option>
              </Select>
            </Form.Item>
          );
        } else {
          switch (record.status) {
            case 0:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color="blue"
                >
                  {t("Open")}
                </Tag>
              );
            case 1:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color="orange"
                >
                  {t("Waiting")}
                </Tag>
              );
            case 2:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color="red"
                >
                  {t("Suspended")}
                </Tag>
              );
            case 3:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color="green"
                >
                  {t("Gained")}
                </Tag>
              );
            case 4:
              return (
                <Tag
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  color="gray"
                >
                  {t("Lost")}
                </Tag>
              );
            default:
              return "__";
          }
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Priceperday")}</h3>,
      dataIndex: "pricePerDay",
      align: "center",
      render: (text, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item name="priceperday">
              <InputNumber
                min={0}
                formatter={(value) => `$${value}`}
                parser={(value) => value.replace("$", "")}
              />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "center" }}>${text}</div>;
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Description")}</h3>,
      dataIndex: "description",
      align: "center",
      render: (text, record) => {
        if (editingRowbook === record.id) {
          return (
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: `${t("Descriptionplaceholder")}`,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModal(text)}
            >
              {text}
            </div>
          );
        }
      },
    },
    {
      title: <h3 style={{ textAlign: "center" }}>{t("Actions")}</h3>,
      dataIndex: "actions",
      align: "center",
      render: (_, record) =>
        editingRowbook === record.key ? (
          <>
            <Button
              type="link"
              onClick={() => {
                setDatebegin(null);
                setEditingRowbook(null);
              }}
              danger
              icon={<CloseCircleFilled />}
            >
              {t("Cancel")}
            </Button>
            <Button type="link" icon={<SaveFilled />} htmlType="submit">
              {t("save")}
            </Button>
          </>
        ) : (
          <>
            {ability.can("update", "Opportunity Book") && (
              <Popconfirm
                type="link"
                onClick={() => {
                  setEditingRowbook(record.key);
                  setDatebegin(dayjs(record.startDate));
                  form2.setFieldsValue({
                    name: record.customer.id,
                    product: record.product.id,
                    marketingType: record.marketingType
                      ? record.marketingType.id
                      : null,
                    startDate: dayjs(record.startDate),
                    endDate: dayjs(record.endDate),
                    status: record.status,
                    description: record.description,
                    priceperday: record.pricePerDay,
                  });
                }}
              >
                <Button type="link" icon={<EditFilled />}>
                  {t("Edit")}
                </Button>
              </Popconfirm>
            )}

            {ability.can("delete", "Opportunity Book") && (
              <Popconfirm
                title={t("Suretodelete")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => handleshareholderDelete(record.id)}
              >
                <Button
                  type="link"
                  danger
                  icon={<CloseCircleFilled />}
                  style={{ marginLeft: ".5rem" }}
                >
                  {t("Delete")}
                </Button>
              </Popconfirm>
            )}
          </>
        ),
    },
  ];

  const [Open, setOpen] = useState({
    open: false,
    url: null,
    data: null,
  });

  const onCreate = async ({ values, url, data }) => {
    if (data == "OrderDetail") {
      const obj = {
        opportunityBookId: stateParamVal,
        customerId: values.customer,
        startDate: values.startDate,
        endDate: values.endDate,
        pricePerDay: values.pricePerDay,
        description: values.description,
        productId: values.product,
        marketingTypeId: values.marketingType,
        status: values.status,
      };

      console.log("obj", obj);

      await axios
        .post(`${JSON_API}/Opportunities`, obj)
        .then(() => {
          getData();
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      values.enterpriseId = Company.id;

      await axios
        .post(`${JSON_API}/${url}`, values)
        .then(() => {
          getData();
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
    setDatebegin(null);
    setOpen(false);
  };

  const Edited = async (values) => {
    console.log("values are :", values);
    setDatebegin(null);

    const orderobj = {
      id: editingRowbook,
      startDate: values.startDate,
      endDate: values.endDate,
      pricePerDay: values.priceperday,
      description: values.description,
      customerId: values.name,
      productId: values.product,
      marketingTypeId: values.marketingType,
      status: values.status,
    };

    const oppstatus = {
      id: editingRowbook,
      opportunityStatus: values.status,
    };
    console.log("opportunityobj is :", orderobj);

    await axios
      .put(`${JSON_API}/Opportunities`, orderobj)
      .then((response) => {
        console.log("Opportunity updated Successfully!", response);

        message.success(`${t("OpportunityupdatedSuccessfully")}`);
        setEditingRowbook(null);
        getData();
      })
      .catch(function (error) {
        if (error.response.status == 404) {
          getData();

          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
        setEditingRowbook(null);
      });
  };

  const onFinishEdit = async (values) => {
    console.log("RowRevenueData:", RowRevenueData);

    const orderbookobj = {
      id: RowRevenueData.revenueDetail.id,
      januaryRevenue: values.januaryRevenue,
      februaryRevenue: values.februaryRevenue,
      marchRevenue: values.marchRevenue,
      aprilRevenue: values.aprilRevenue,
      mayRevenue: values.mayRevenue,
      juneRevenue: values.juneRevenue,
      julyRevenue: values.julyRevenue,
      augustRevenue: values.augustRevenue,
      septemberRevenue: values.septemberRevenue,
      octoberRevenue: values.octoberRevenue,
      novemberRevenue: values.novemberRevenue,
      decemberRevenue: values.decemberRevenue,
    };

    console.log("Opportunitycostedited: ", orderbookobj);

    await axios
      .put(`${JSON_API}/Opportunities/UpdateRevenueDetail`, orderbookobj)
      .then((response) => {
        console.log("Opportunity updated Successfully!", response);
        getData();
        setRevenueDataSource([response.data.revenueDetail]);

        message.success(`${t("OpportunityrevenuedetailsupdatedSuccessfully")}`);
      });
    setEditingRow(null);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows[0]
      );

      const obj = selectedRows[0].revenueDetail;

      setRowRevenueData(selectedRows[0]);

      obj.key = selectedRowKeys[0];

      setRevenueDataSource([obj]);
    },
  };

  const DisplayRevenueSummary = () => {
    console.log("ttesstt 1");
    const items = [];

    const months = [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ];

    const revenueData = [
      SummaryDetails && SummaryDetails.januaryRevenue,
      SummaryDetails && SummaryDetails.februaryRevenue,
      SummaryDetails && SummaryDetails.marchRevenue,
      SummaryDetails && SummaryDetails.aprilRevenue,
      SummaryDetails && SummaryDetails.mayRevenue,
      SummaryDetails && SummaryDetails.juneRevenue,
      SummaryDetails && SummaryDetails.julyRevenue,
      SummaryDetails && SummaryDetails.augustRevenue,
      SummaryDetails && SummaryDetails.septemberRevenue,
      SummaryDetails && SummaryDetails.octoberRevenue,
      SummaryDetails && SummaryDetails.novemberRevenue,
      SummaryDetails && SummaryDetails.decemberRevenue,
    ];

    const startingMonthIndex = Company.startPeriod - 1;

    for (let i = 0; i < months.length; i++) {
      const monthIndex = (i + startingMonthIndex) % months.length;
      const monthName = months[monthIndex];
      const revenue = revenueData[monthIndex];

      items.push(
        <Descriptions.Item
          key={monthName}
          style={{ textAlign: "center" }}
          label={monthName}
        >
          {revenue}
        </Descriptions.Item>
      );
    }

    return (
      <Descriptions
        style={{ textAlign: "right" }}
        bordered
        column={6}
        size={"small"}
      >
        {items}
      </Descriptions>
    );
  };

  return (
    <>
      {contextHolder}

      <Statistic
        title={t("BookTotal")}
        value={SummaryDetails && SummaryDetails.bookTotal}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
        suffix={
          Company.currencies &&
          Company.currencies.length > 0 &&
          Company.currencies[0].shortLabel
            ? Company.currencies[0].shortLabel
            : "$"
        }
      />
      <Title level={4}>{t("RevenueSummary")}</Title>

      <DisplayRevenueSummary />

      <Title level={4}>{t("opportunities")}</Title>

      <Row
        justify="end"
        gutter={[16, 16]}
        style={{
          marginBottom: 24,
          marginRight: 18,
        }}
      >
        {ability.can("create", "Opportunity Book") && (
          <Space align="center">
            <Button
              onClick={() => {
                setOpen({ open: true, url: "Customers", data: "Customer" });
              }}
            >
              <PlusCircleFilled /> {t("CreateCustomer")}
            </Button>

            <Button
              onClick={() => {
                setOpen({ open: true, url: "Products", data: "Product" });
              }}
            >
              <PlusCircleFilled />
              {t("CreateProduct")}
            </Button>

            <Button
              type="primary"
              onClick={() => {
                setOpen({
                  open: true,
                  url: "orderdetail",
                  data: "OrderDetail",
                });
              }}
            >
              <PlusCircleFilled /> {t("Createopportunity")}
            </Button>
          </Space>
        )}
      </Row>

      <CollectionCreateForm
        open={Open.open}
        onCreate={onCreate}
        onCancel={() => {
          form.resetFields();
          setDatebegin(null);
          setOpen({ open: false, url: null, data: null });
        }}
        data={Open}
      />

      <Form form={form2} onFinish={Edited}>
        <div style={{ overflowX: "auto", backgroundColor: "white" }}>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            bordered
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={OrderDetails}
          />
        </div>
        <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
      </Form>

      <Title level={4}>{t("RevenueDetail")}</Title>
      <Form form={form1} onFinish={onFinishEdit}>
        <Table
          locale={{
            emptyText: <Empty description={t("nodata")} />,
          }}
          bordered
          pagination={{ current: currentPage }}
          onChange={handleTableChange}
          dataSource={costDataSource && costDataSource}
          columns={costdetailsColumns}
          scroll={{
            x: 1300,
          }}
        />
      </Form>
    </>
  );
};

OpportunityDetails.acl = {
  subject: "Opportunity Book",
  action: "read",
};

export default OpportunityDetails;
