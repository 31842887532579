// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-header {
  background-color: #ffff;
  padding: 20px;
  color: white;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Options,
.Configuration,
.Charts,
.ResultsTable,
.CreatorCharts {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  width: 80%;
  background-color: white;
}

.Charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Chart {
  width: 50%;
  margin: 20px 0;
}

.ResultsTable {
  width: 80%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Revenue/components/popup.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;;;;;EAKE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-header {\n  background-color: #ffff;\n  padding: 20px;\n  color: white;\n}\n\n.Content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.Options,\n.Configuration,\n.Charts,\n.ResultsTable,\n.CreatorCharts {\n  margin: 20px;\n  padding: 20px;\n  border: 1px solid #ccc;\n  width: 80%;\n  background-color: white;\n}\n\n.Charts {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n}\n\n.Chart {\n  width: 50%;\n  margin: 20px 0;\n}\n\n.ResultsTable {\n  width: 80%;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n}\n\nth,\ntd {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\nth {\n  background-color: #f2f2f2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
