import {
  DollarTwoTone,
  EyeFilled,
  InfoCircleOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Row,
  Space,
  Table,
  Modal,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { useAbility } from "../../contexts/abilityContext";
import { JSON_API } from "../../services/Constants";
import SimulationModal from "./components/SimulationModal";
function Revenue() {
  const { ability } = useAbility();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [revenue, setrevenue] = useState(null);
  const [form2] = Form.useForm();
  const [simulationModalOpen, setSimulationModalOpen] = useState(false);

  var date;
  useEffect(() => {
    console.log("year" + date);
    getExpense();
  }, [Company.id, year]);
  const onChangeyear = (date, dateString) => {
    setrevenue(null);
    console.log(date, dateString);
    setYear(dateString);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPagerevenue")) || 1;
  });
  const handleCloseSimulationModal = () => {
    setSimulationModalOpen(false);
  };
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPagerevenue", pagination.current);
  };
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const getExpense = async () => {
    if (year == null) {
      await axios
        .get(`${JSON_API}/Revenue/filter/${Company.id}`)
        .then((res) => {
          console.log(res);

          setrevenue(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(`${JSON_API}/Revenue/filter/${Company.id}?year=${year}`)
        .then((res) => {
          console.log(res);
          setrevenue(res.data);
          console.log(revenue);
          console.log("resss", res.revenueType.isRecurrent);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const columns = [
    {
      title: `${t("glnumber")}`,
      // dataIndex: "glAccountId",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.chartAccount.glAccount.glNumber}
        </div>
      ),
    },

    {
      title: `${t("Category")}`, // dataIndex: "category",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "left" }}>
          {currentLang === "en"
            ? record.financialStatementType.financialStatementCategory.label
            : record.financialStatementType.financialStatementCategory.labelFr}
        </div>
      ),
    },

    {
      title: `${t("Gifi")}`,
      // dataIndex: "financialStatementTypeId",
      align: "center",
      // render: (text) => <a>{text}</a>,
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.financialStatementType.gifi}
        </div>
      ),
    },
    {
      title: "Description",
      // dataIndex: "note",
      align: "center",
      render: (_, record) => (
        <div
          style={{
            textAlign: "left",
            maxWidth: "400px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() =>
            showNameModal(record.chartAccount.glAccount.description)
          }
        >
          {record.chartAccount.glAccount.description}
        </div>
      ),
    },

    {
      title: `${t("Revenutype")}`, // dataIndex: "category",
      align: "center",

      render: (_, record) => {
        if (!record.revenueType) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }
        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.revenueType.labelEn
              : record.revenueType.labelFr}
          </div>
        );
      },
    },
    {
      title: `${t("Recurrent")}`, // dataIndex: "category",
      align: "center",

      render: (_, record) => {
        if (!record.revenueType) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }
        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {record.revenueType.isRecurrent ? t("Yes") : t("No")}
          </div>
        );
      },
    },

    {
      title: `${t("RecurrentFrequency")}`,
      align: "center",

      render: (_, record) => {
        if (!record.recurrentFrequency) {
          return (
            <div
              style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              -
            </div>
          );
        }
        return (
          <div
            style={{
              textAlign: "left",
              maxWidth: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            {currentLang === "en"
              ? record.recurrentFrequency.labelEn
              : record.recurrentFrequency.labelFr}
          </div>
        );
      },
    },
    {
      title: "Actions",
      align: "center",

      render: (_, record) => {
        return (
          <Link
            to={{
              pathname: `/revenueDetails/${record.id}`,
              state: { stateParam: record.id },
            }}
          >
            <Button type="link" icon={<EyeFilled />}>
              {t("details")}
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <h3 className="font-semibold">
          {" "}
          <DollarTwoTone /> {t("Revenue")}
        </h3>
      }
    >
      <Row justify="space-between">
        <Space
          style={{
            display: "flex",
            // marginBottom: 8,
            marginLeft: "flex",
          }}
          align="baseline"
        >
          <DatePicker
            name="year"
            picker="year"
            placeholder={t("Selectyear")}
            style={{ width: 200, height: 35 }}
            onChange={onChangeyear}
          />
        </Space>

        <Space
          style={{
            // display: 'flex',
            marginBottom: 24,
          }}
        >
          <Button
            className="Create-button"
            type="primary"
            style={{
              textAlign: "right",
              marginLeft: "flex",
            }}
            onClick={() => setSimulationModalOpen(true)}
          >
            <SyncOutlined />
            {t("RunSimulation")}
          </Button>
          <Link
            to={{
              pathname: `/summaryrevenue`,
            }}
          >
            <Button
              className="Create-button"
              type="primary"
              style={{
                textAlign: "right",
                marginLeft: "flex",
              }}
            >
              <InfoCircleOutlined />
              {t("Summary")}
            </Button>
          </Link>
          {/* {ability.can("update", "Expense") && (
            <Button
              className="Create-button"
              type="primary"
              // disabled
              style={{
                textAlign: "right",
                marginLeft: "flex",
              }}
              onClick={() => {
                // setOpenupdate(true);
              }}
            >
              <UploadOutlined />
              {t("Upload")}
            </Button>
          )} */}
        </Space>
      </Row>
      <div style={{ overflowX: "auto" }}>
        <Table
          locale={{
            emptyText: <Empty description={t("nodata")} />,
          }}
          pagination={{ current: currentPage }}
          onChange={handleTableChange}
          columns={columns}
          dataSource={revenue}
          bordered
        />
      </div>
      <Modal
        title={t("detail")}
        visible={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <p>{selectedName}</p>
      </Modal>
      <SimulationModal
        open={simulationModalOpen}
        handleClose={handleCloseSimulationModal}
        enterprise={Company}
      />
    </Card>
  );
}
Revenue.acl = {
  subject: "Revenue",
  action: "read",
};

export default Revenue;
