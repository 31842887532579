import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import BudgetRevenue from "./Tables/BudgetRevenue";
import HyphothesisOfGlRe from "./Tables/HyphothesisOfGlRe";
import PerformanceUnit from "./Tables/PerfermanceUnit";
import PerformanceRevenue from "./Tables/PerformanceRevenue";
import RealRevenue from "./Tables/RealRevenue";
import SoldeBExpected from "./Tables/SoldeBExpected";

import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import RealUnit from "./Tables/RealUnit";
import { useAbility } from "../../contexts/abilityContext";
import SimulationModal from "./components/SimulationModal";
const { Option } = Select;

const { TextArea } = Input;
const { Text } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};


function RevenueDetails() {
  const { ability } = useAbility();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const [gifi, setGifi] = useState("");
  const [glAccount, setglAccount] = useState(null);
  const [Hypo, setHypo] = useState(null);
  const [statementcategory, setStatementCategory] = useState([{}]);
  const [employe, setemploye] = useState([{}]);
  const [statementclass, setStatementClass] = useState([{}]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [Asset, setAsset] = useState([]);
  const [Real, setReal] = useState([]);
  const [Realunit, setRealunit] = useState([]);
  const [performance, setperformance] = useState([]);
  const [performanceunit, setperformanceunit] = useState([]);
  const [Note, setNote] = useState("");
  const [Category, setCategory] = useState();
  const [CategoryName, setCategoryName] = useState();
  const [Class, setClass] = useState();
  const [open, setOpen] = useState(false);
  const [paymentTermId, setpaymentTermId] = useState();
  const [paymentTermlabel, setpaymentTermlabel] = useState("");
  const [productlabel, setproductlabel] = useState("");
  const [sellingPrice, setsellingPrice] = useState();
  const [commission, setcommission] = useState();
  const [employeeId, setemployeeId] = useState();
  const [productId, setproductId] = useState();
  const [payment, setpayment] = useState([{}]);
  const [product, setproduct] = useState([{}]);
  const [description, setdescription] = useState("");
  const [glAccountDescription, setGlAccountDescription] = useState("");
  const [RecurrentFrequency, setRecurrentFrequency] = useState([{}]);
  const [Recurrent, setRecurrent] = useState();
  const [RecurrentName, setRecurrentName] = useState();
  const [isRecurrentDisabled, setIsRecurrentDisabled] = useState(false);

  const [revenuetype, setrevenuetype] = useState([{}]);

  const [Revenu, setRevenu] = useState();

  const [RevenuName, setRevenuName] = useState();
  const [initialRecurrent, setInitialRecurrent] = useState(null);
  const [displayRecurrent, setDisplayRecurrent] = useState(null);
  const [simulationModalOpen, setSimulationModalOpen] = useState(false);
  
  var paymentpayment = [];
  var productlabell = [];
  const canUpdate = ability.can("update", "Revenue");
  const history = useHistory();

  const onChangePrice = (e) => {
    setsellingPrice(e);
    setAsset((prevState) => {
      return { ...prevState, sellingPrice: e };
    });
  };
  useEffect(() => {
    Payement();
    Product();
    getRevenue();
    getReals();
    getPerformance();
    getStatementClass();
    getHypothesis();
    getRealUnit();
    getRealUnitPerformance();
    getRecurrentFrequency();
    getRevenuetype();
  }, [Company.id]);

  useEffect(() => {
    if (Revenu) {
      const selectedRevenueType = revenuetype.find(
        (type) => type.id === Revenu
      );
      if (selectedRevenueType) {
        setIsRecurrentDisabled(!selectedRevenueType.isRecurrent);
        if (!selectedRevenueType.isRecurrent) {
          setRecurrent(null); // Réinitialiser si non récurrent
        }
      }
    }
  }, [Revenu, revenuetype]);
  function handleredirection() {
    history.push("/revenue");
    getRevenue();
  }
  // Fonction déclenchée lors du changement du type de revenu
  const handleCloseSimulationModal = () => {
    setSimulationModalOpen(false);
  };

  const handleRevenueTypeChange = (value) => {
    const selectedRevenueType = revenuetype.find((type) => type.id === value);
    setRevenu(value);

    if (selectedRevenueType) {
      const isRecurrent = selectedRevenueType.isRecurrent;
      setIsRecurrentDisabled(!isRecurrent);

      if (isRecurrent) {
        setDisplayRecurrent(initialRecurrent);
        setRecurrent(initialRecurrent);
      } else {
        setDisplayRecurrent(initialRecurrent);
        setRecurrent(null);
      }
    }
  };
  const handleRecurrentChange = (value) => {
    setRecurrent(value);
    setDisplayRecurrent(value);
  };

  const Payement = async () => {
    await axios
      .get(`${JSON_API}/PaymentTerm`)
      .then((res) => {
        setpayment(res.data);
        paymentpayment = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Product = async () => {
    await axios
      .get(`${JSON_API}/Products/Enterprise/${Company.id}`)
      .then((res) => {
        setproduct(res.data);
        productlabell = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRecurrentFrequency = async () => {
    try {
      const response = await axios.get(`${JSON_API}/RecurrentFrequencys`);
      setRecurrentFrequency(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getRevenuetype = async () => {
    try {
      const response = await axios.get(`${JSON_API}/RevenueTypes`);
      setrevenuetype(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getRevenue = async () => {
    try {
      const res = await axios.get(`${JSON_API}/Revenue/RevenueBudgets/${id}`);
      setAsset(res.data);
      setNote(res.data.note);

      if (res.data.financialStatementCategory) {
        setCategory(res.data.financialStatementCategory.id);
        setCategoryName(
          currentLang === "fr"
            ? res.data.financialStatementCategory.labelFr
            : res.data.financialStatementCategory.label
        );
      }

      if (res.data.revenueType) {
        setRevenu(res.data.revenueType.id);
        setRevenuName(
          currentLang === "fr"
            ? res.data.revenueType.labelFr
            : res.data.revenueType.labelEn
        );
        setIsRecurrentDisabled(!res.data.revenueType.isRecurrent);
      }

      if (res.data.recurrentFrequency) {
        const recurrentId = res.data.recurrentFrequency.id;
        setInitialRecurrent(recurrentId);
        setRecurrent(recurrentId);
        setDisplayRecurrent(recurrentId);
        setRecurrentName(
          currentLang === "fr"
            ? res.data.recurrentFrequency.labelFr
            : res.data.recurrentFrequency.labelEn
        );
      }

      if (res.data.financialStatementClass) {
        setClass(res.data.financialStatementClass.id);
        // Assumez que handleclass est déjà capable de gérer le changement de langue
        handleclass(res.data.financialStatementClass.id);
      }

      setGifi(res.data.financialStatementType.gifi);
      setglAccount(res.data.glAccount.glNumber);
      setGlAccountDescription(
        `(${res.data.glAccount.glNumber}) : ${res.data.glAccount.description}`
      );
      setdescription(res.data.glAccount.description);
      getHypothesis(res.data.glAccount.id);
      setpaymentTermId(res.data.paymentTermId);

      const paymentTerm = payment.find((e) => e.id === res.data.paymentTermId);
      setpaymentTermlabel(paymentTerm ? paymentTerm.label : "");

      setproductId(res.data.productId);
      const product = productlabell.find((e) => e.id === res.data.productId);
      setproductlabel(product ? product.label : "");

      setsellingPrice(res.data.sellingPrice);
      setcommission(res.data.commission);
      setemployeeId(res.data.employeeId);
    } catch (err) {
      console.error("Error fetching revenue details:", err);
    }
  };

  const getPerformance = async () => {
    await axios
      .get(`${JSON_API}/Revenue/RevenuePerformance/${id}`)
      .then((res) => {
        setperformance(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }; 
  const getReals = async () => {
    await axios
      .get(`${JSON_API}/Revenue/RevenueReal/${id}`)
      .then((res) => {
        console.log("test", res.data);
        setReal(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRealUnit = async () => {
    await axios
      .get(`${JSON_API}/Revenue/RevenueUnitReal/${id}`)
      .then((res) => {
        setRealunit(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRealUnitPerformance = async () => {
    await axios
      .get(`${JSON_API}/Revenue/RevenueUnitPerformance/${id}`)
      .then((res) => {
        setperformanceunit(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteFinancialStatement = async () => {
    await axios
      .delete(`${JSON_API}/Revenue/${Asset.id}`)
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const UpdateFinancialStatement = async () => {
    const obj = {
      id: Asset.id,
      financialStatementClassId: Asset.financialStatementClass.id,
      financialStatementCategoryId: Category,
      revenueTypeId: Revenu,
      recurrentFrequencyId: Recurrent,
      note: Note,
      budgets: Asset.budgets,
      units: Asset.units,
      reals: Real.reals,
      unitReals: Realunit.unitReals,
      paymentTermId: paymentTermId,
      sellingPrice: sellingPrice,
      commission: commission,
      employeeId: employeeId,
      productId: productId,
    };
    await axios
      .put(`${JSON_API}/Revenue/RevenueBudgetRealUpdate`, obj)
      .then((res) => {
        getRevenue();
        message.success(t("UpdatedSuccessfully"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];
  function handleBudgetChange(budget) {
    setAsset((prevState) => {
      return { ...prevState, budgets: budget };
    });
    Real.reals.map((e) => {
      budget.map((Budget) => {
        if (e.year == Budget.year) {
          const confirm = !Budget.confirmed;
          if (confirm) {
            const startingMonthIndex = Company.startPeriod - 1;
            for (let i = 0; i < months.length; i++) {
              const monthIndex = (i + startingMonthIndex) % months.length;
              const monthName = months[monthIndex];
              e.reals[monthIndex] = Budget.budgets[monthIndex];
            }
          }
        }
      });
    });
  }

  function handleRealUnitChange(real) {
    setAsset((prevState) => {
      return { ...prevState, unitReals: real };
    });
  }

  function handleUnitChange(unit) {
    setAsset((prevState) => {
      return { ...prevState, units: unit };
    });
    Realunit.unitReals.map((e) => {
      unit.map((Budget) => {
        if (e.year == Budget.year) {
          const confirm = !Budget.confirmed;
          if (confirm) {
            const startingMonthIndex = Company.startPeriod - 1;
            for (let i = 0; i < months.length; i++) {
              const monthIndex = (i + startingMonthIndex) % months.length;
              const monthName = months[monthIndex];
              e.unitReals[monthIndex] = Budget.units[monthIndex];
            }
          }
        }
      });
    });
  }

  function handleRealChange(real) {
    setReal((prevState) => {
      return { ...prevState, reals: real };
    });
  }

  useEffect(() => {
    getStatementClass();
    handleclass();
    getHypothesis();
    Employee();
  }, []);

  const getHypothesis = async (e) => {
    await axios
      .get(`${JSON_API}/GLAccount/${e}`)
      .then((res) => {
        setHypo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleclass = async (ClassId) => {
    await axios
      .get(`${JSON_API}/FinancialStatementCategory/class/${ClassId}`)
      .then((res) => {
        setStatementCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Employee = async (ClassId) => {
    await axios
      .get(`${JSON_API}/Employees/enterprise/filter?enterpriseId=${Company.id}`)
      .then((res) => {
        setemploye(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatementClass = async () => {
    await axios
      .get(`${JSON_API}/FinancialStatementClass`)
      .then((res) => {
        setStatementClass(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const itemsForm = [
    {
      key: "1",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Maininformation")}
        </span>
      ),
      children: Asset.budgets && (
        <div>
          <Form
            {...layout}
            name="nest-messages"
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <Form.Item label={t("Class")}>
              <Input
                value={
                  currentLang === "fr"
                    ? Asset.financialStatementClass?.labelFr
                    : Asset.financialStatementClass?.label
                }
                disabled
              />
            </Form.Item>

            <Form.Item label={t("Category")}>
              <Select
                disabled={!canUpdate}
                value={Category}
                onChange={(value) => setCategory(value)}
              >
                {statementcategory &&
                  statementcategory.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {currentLang === "fr" ? e.labelFr : e.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={"Type"}>
              <Input value={gifi} disabled />
            </Form.Item>

            <Form.Item label={t("glnumber")}>
              <Input value={glAccountDescription} disabled={true} />
            </Form.Item>

            <Form.Item label={t("PayementTerme")}>
              <Select
                placeholder={t("PleaseSelectPaymentTerm")}
                disabled={!canUpdate}
                value={paymentTermId}
                onChange={(value) => setpaymentTermId(value)}
              >
                {payment &&
                  payment.map(
                    (e) =>
                      e && (
                        <Option key={e.id} value={e.id}>
                          {e.label}
                        </Option>
                      )
                  )}
              </Select>
            </Form.Item>

            <Form.Item label={t("Product")}>
              <Select
                placeholder={t("PleaseSelectProduct")}
                disabled={!canUpdate}
                value={productId}
                onChange={(value) => setproductId(value)}
              >
                {product &&
                  product.map(
                    (e) => e && <Option value={e.id}>{e.label}</Option>
                  )}
              </Select>
            </Form.Item>

            <Form.Item label={t("sellingPrice")}>
              <InputNumber
                disabled={!canUpdate}
                value={sellingPrice}
                onChange={(value) => onChangePrice(value)}
              ></InputNumber>
            </Form.Item>

            <Form.Item label={t("Employee")}>
              <Select
                placeholder={t("PleaseSelectEmployee")}
                disabled={!canUpdate}
                value={employeeId}
                onChange={(value) => setemployeeId(value)}
              >
                {employe &&
                  employe.map(
                    (e) =>
                      e && (
                        <Option
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            fontSize: ".8rem",
                          }}
                          value={e.id}
                        >
                          {e.name} {e.firstName}
                        </Option>
                      )
                  )}
              </Select>
            </Form.Item>

            <Form.Item label="Commission">
              <Input
                disabled={!canUpdate}
                value={commission}
                onChange={(e) => setcommission(e.target.value)}
              />
            </Form.Item>

            <Form.Item label={t("Revenutype")}>
              <Select
                disabled={!canUpdate}
                value={Revenu}
                onChange={handleRevenueTypeChange} // Appeler la fonction lors du changement
                placeholder={t("SelectRevenueType")}
              >
                {revenuetype &&
                  revenuetype.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {currentLang === "fr" ? e.labelFr : e.labelEn}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {/* Champ pour la fréquence récurrente */}
            <Form.Item label={t("RecurrentFrequency")}>
              <Select
                disabled={isRecurrentDisabled || !canUpdate}
                value={displayRecurrent}
                onChange={handleRecurrentChange}
                placeholder={t("SelectFrequency")}
              >
                {RecurrentFrequency &&
                  RecurrentFrequency.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {currentLang === "fr" ? e.labelFr : e.labelEn}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item></Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Hyphothesis")}
        </span>
      ),
      children: (
        <div>{Hypo && <HyphothesisOfGlRe HypothesesDataSource={Hypo} />}</div>
      ),
    },
  ];

  // for table Buget ,Reals and Performance
  const items = [
    {
      key: "1",
      label: <span style={{ width: 300, textAlign: "center" }}>Budget</span>,
      children: (
        <div>
          {Asset.budgets && (
            <BudgetRevenue
              AssetUnit={Asset.financialStatementClass && Asset.units}
              Revenue={Asset.financialStatementClass && Asset}
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              onBudgetChange={handleBudgetChange}
            />
          )}
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>{t("Real")}</span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <RealRevenue
              AssetUnit={Realunit.unitReals && Realunit.unitReals}
              Revenue={Asset.financialStatementClass && Asset}
              AssetReals={Real.reals}
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              onRealChange={handleRealChange}
            />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>Performance</span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <PerformanceRevenue
              AssetReals={Real.reals}
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              AssetPerformance={performance.performances}
            />
          )}
        </div>
      ),
    },
  ];
  const itemsUnit = [
    {
      key: "1",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("ExpectedSoldUnits")}
        </span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <SoldeBExpected
              Revenue={Asset.financialStatementClass && Asset}
              AssetUnit={Asset.financialStatementClass && Asset.budgets}
              AssetBudgets={Asset.financialStatementClass && Asset.units}
              onBudgetChange={handleUnitChange}
            />
          )}
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("RealSoldUnit")}
        </span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <RealUnit
              AssetUnit={Asset.financialStatementClass && Asset.budgets}
              AssetReals={Realunit.unitReals}
              AssetBudgets={Asset.financialStatementClass && Asset.units}
              onRealChange={handleRealUnitChange}
            />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>Performance</span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <PerformanceUnit
              AssetReals={Realunit.unitReals}
              AssetBudgets={Asset.financialStatementClass && Asset.units}
              AssetPerformance={performanceunit.unitPerformances}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}

      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <h3 className="font-semibold">
            {t("RevenueDetails")} {description}
          </h3>
        }
      >
        <Row justify="space-between">
          <ConfigProvider
            theme={{
              token: {},
            }}
          >
            <Space
              style={{
                display: "flex",
                marginLeft: "flex",
              }}
              align="baseline"
            >
              {ability.can("update", "Revenue") && (
                <Button
                  type="primary"
                  size="medium"
                  textAlign="center"
                  onClick={UpdateFinancialStatement}
                >
                  <SaveOutlined />
                  {t("save")}
                </Button>
              )}
            </Space>
          </ConfigProvider>

          <Space>
          <Button
              className="Create-button"
              type="primary"
              style={{
                textAlign: "right",
                marginLeft: "flex",
              }}
              onClick={() => setSimulationModalOpen(true)}
            >
              <SyncOutlined />
              {t("RunSimulation")}
            </Button>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FFA805",
                },
              }}
            >
              <Link to="/revenue">
                {ability.can("read", "Revenue") && (
                  <Button
                    type="primary"
                    style={{
                      textAlign: "right",
                      marginLeft: "flex",
                    }}
                  >
                    <InfoCircleOutlined />
                    {t("backtofinancialstatement")}
                  </Button>
                )}
              </Link>
            </ConfigProvider>

            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FF0606",
                },
              }}
            >
              {ability.can("delete", "Revenue") && (
                <Button
                  type="primary"
                  style={{
                    textAlign: "right",
                    marginLeft: "flex",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <DeleteOutlined />
                  {t("deleteStatement")}
                </Button>
              )}
              <Modal
                title={t("Deletethestatment")}
                showIcon
                closable
                description={t("Areyousuretodeletethisone")}
                centered
                open={open}
                onOk={() => {
                  setOpen(false);
                  DeleteFinancialStatement(false);
                  handleredirection();
                }}
                okText={t("yes")}
                cancelText={t("Cancel")}
                onCancel={() => setOpen(false)}
                width={400}
              >
                <span>{t("Areyousuretodeletethisone")}</span>
              </Modal>
            </ConfigProvider>
          </Space>
        </Row>
        <br></br>

        {/* <Table columns={columns} dataSource={ChartofAccounts} bordered /> */}
        <Tabs
          style={{ marginBottom: 32, Color: "#059BFF" }}
          type="card"
          centered
          defaultActiveKey="1"
          items={itemsForm}
        />

        <div>
          <Tabs
            style={{ marginBottom: 32, Color: "#059BFF" }}
            type="card"
            centered
            defaultActiveKey="1"
            items={itemsUnit}
          />
        </div>
        <div>
          <Tabs
            style={{ marginBottom: 32, Color: "#059BFF" }}
            type="card"
            centered
            defaultActiveKey="1"
            items={items}
          />
        </div>
        <SimulationModal
        open={simulationModalOpen}
        handleClose={handleCloseSimulationModal}
        enterprise={Company}
        revenue={{
          revenue_id: Asset.id,
          enterprise: Company.id,
          description: Asset.glAccount?.description,
        }}
      />
      </Card>
    </div>
  );
}
RevenueDetails.acl = {
  subject: "Revenue",
  action: "read",
};

export default RevenueDetails;
